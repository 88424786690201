import { Injectable } from '@angular/core';
import { debounceTime, map, Observable, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Agency, role, searchUsersParams, User, userRoleParams } from '@domain';
import { HasStore } from '@application';
import { UserManagementRepository } from '@infrastructure';

export type userState = {
	users: User[] | null;
	userAgenciesRoles: Map<Agency, role[]> | null;
	availableRoles: role[] | null;
	currentUser: User | null;
};

@Injectable({
	providedIn: 'root',
})
export default class UserManagementManager extends HasStore<userState> {
	constructor(
		private readonly userRepository: UserManagementRepository,
		private readonly toaster: ToastrService,
	) {
		super(
			{
				users: null,
				userAgenciesRoles: null,
				availableRoles: null,
				currentUser: null,
			},
			'user-management',
		);
	}

	getUsers(params: searchUsersParams) {
		return this.userRepository
			.getUsers(params)
			.pipe(take(1))
			.subscribe(users => {
				this.store.update(state => {
					return {
						...state,
						users: users,
					};
				});
			});
	}

	getUserById(userID: string) {
		return this.userRepository
			.getUserById(userID)
			.pipe(take(1))
			.subscribe(user => {
				this.store.update(state => {
					return {
						...state,
						currentUser: user,
					};
				});
			});
	}

	getUserRoles(userId: string) {
		return this.userRepository
			.getUserRoles(userId)
			.pipe(take(1))
			.subscribe(agenciesRoles => {
				this.store.update(state => {
					return {
						...state,
						userAgenciesRoles: agenciesRoles,
					};
				});
			});
	}

	getAvailableRoles() {
		return this.userRepository
			.getAvailableRoles()
			.pipe(take(1))
			.subscribe(roles => {
				this.store.update(state => {
					return {
						...state,
						availableRoles: roles,
					};
				});
			});
	}

	addUserRole(params: userRoleParams): Observable<boolean> {
		return this.userRepository.addRole(params).pipe(
			take(1),
			map(() => {
				this.toaster.success('Le rôle a bien été ajouté', '', { timeOut: 1600 });
				return true;
			}),
		);
	}

	removeUserRole(params: userRoleParams): Observable<boolean> {
		return this.userRepository.removeRole(params).pipe(
			debounceTime(0),
			take(1),
			map(() => {
				this.toaster.success('Le rôle a bien été retiré', '', { timeOut: 800 });
				return true;
			}),
		);
	}
}
