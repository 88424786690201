import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { combineLatest, debounceTime, Observable, of, switchMap, takeUntil } from 'rxjs';
import { select } from '@ngneat/elf';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActivatedRoute, Router, RouterLinkActive } from '@angular/router';
import { BaseComponent, className, DropdownComponent, SelectFieldComponent } from '@components';
import { Agency, preference, role, User, userRoleParams } from '@domain';
import UserManagementManager, { userState } from '../../../application/user-management/user-management.manager';
import AgencyManager from '../../../application/agency/agency.manager';
import { AuthenticationManager, PreferenceManager } from '@application';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

@Component({
	selector: 'intranet-user-details',
	standalone: true,
	imports: [CommonModule, FormsModule, NgSelectModule, FaIconComponent, DropdownComponent, SelectFieldComponent, RouterLinkActive, ConfirmDialogModule],
	templateUrl: './user-details.component.html',
	styleUrl: './user-details.component.scss',
	providers: [ConfirmationService],
})
export class UserDetailsComponent extends BaseComponent implements OnInit {
	public icons = icons;
	public className = className;
	// public Array = Array;
	public userId: string | null = null;
	public currentUser: User | null = null;

	// LINE DISPLAYED
	public userAgencyRoles: Map<Agency, role[]> | null = null;

	// ROLES AVAILABLE DISPLAYED
	public rolesList = new Array<{ label: string; value: number }>();

	// ALL AGENCIES
	public agencies = new Array<Agency>();

	// AVAILABLE AGENCIES THAT CAN BE ADDED
	public selectableAgencies = new Array<Agency>();
	public isReadOnly: WritableSignal<boolean> = signal(false);

	constructor(
		private userManager: UserManagementManager,
		private agencyManager: AgencyManager,
		private route: ActivatedRoute,
		private readonly router: Router,
		private readonly authenticationManager: AuthenticationManager,
		private confirmationService: ConfirmationService,
		private preferenceManager: PreferenceManager,
	) {
		super();
	}

	ngOnInit(): void {
		// RETRIEVE THE USER ID FROM THE URL
		if (this.router.url.includes('parametres')) {
			this.userId = this.authenticationManager.store.value.authenticatedUserData?.userId as string;
			this.isReadOnly.set(true);
		} else {
			this.userId = this.route.snapshot.params['userId'];
		}

		// SUBSCRIBE TO USER
		this.subscribeUser();

		// SUBSCRIBE TO AVAILABLE ROLES
		this.subscribeAvailableRoles();

		// SUBSCRIBE TO USER ROLES
		this.subscribeUserRoles();

		// GET THE CURRENT USER INFO
		this.userManager.getUserById(this.userId!);

		// GET AVAILABLE ROLES
		this.userManager.getAvailableRoles();

		// INITIALIZE THE AGENCIES AND GET THE AGENCY ROLES
		this.initializeAgencies();
	}

	public addAgencyRoleLine() {
		//INIITALIZE THE OBJ IF IT ID THE FIRST LINE
		if (this.userAgencyRoles == null) {
			this.userAgencyRoles = new Map<Agency, role[]>();
		}

		// INITIALIZE THE LINE WITH THE FIRST ITEM OF THE SELECTABLE AGENCIES AND REMOVE IT THE SELECTABLE LIST
		this.userAgencyRoles.set(this.selectableAgencies[0], []);
		this.selectableAgencies.splice(0, 1);
	}

	public refreshSelectableAgencies(data: { index: number; selectedAgency: Agency }) {
		// REMOVE THE PREVIOUS AGENCY AND SET THE NEW ONE
		let previousAgency = Array.from(this.userAgencyRoles!.keys())[data.index];
		this.userAgencyRoles?.delete(previousAgency);
		this.userAgencyRoles?.set(data.selectedAgency, []);

		// RESET THE SELECTABLE AGENCIES
		this.selectableAgencies = [...this.agencies];

		// LOOP ON AGENCIES THAT THE USER CAN HAVE ROLES
		this.agencies.forEach(agency => {
			// CHECK IF SOME ROLES ALREADY BEEN ADDED
			if (this.userAgencyRoles?.has(agency)) {
				// REMOVE IT FROM THE SELECTABLE ARRAY
				let index = this.selectableAgencies.findIndex(a => a.id == agency.id);
				this.selectableAgencies.splice(index, 1);
			}
		});
	}

	public getUserAgenciesAvailable() {
		// RETURN ONLY AGENCIES THAT ARE NOT PRESENT IN THE USER AGENCY ROLES
		return this.agencies.filter(a => {
			let agencyFound = Array.from(this.userAgencyRoles!.keys()).find((agency: Agency) => {
				return agency!.name == a.name;
			});
			return agencyFound == undefined;
		});
	}

	public removeAgencyRole(userAgencyRole: { key: Agency; value: role[] }) {
		// RETRIEVE THE AGENCY TO DELETE IN THE MAP OBJ
		if (userAgencyRole.value.length > 0) {
			let calls: Observable<boolean>[] = [];
			// REMOVE EACH ROLE OF THE AGENCY
			userAgencyRole.value.forEach(r => {
				let params: userRoleParams = {
					userId: this.userId!,
					agencyId: userAgencyRole.key.id!,
					roleId: r.id,
				};

				// CALL SERVER
				calls.push(this.userManager.removeUserRole(params));
			});

			combineLatest(calls)
				.pipe(
					debounceTime(0),
					switchMap(() => {
						// CHECK IF THE CURRENT USER IS THE SAME AS THE USER CONNECTED TO THE APP
						if ((this.authenticationManager.store.value.authenticatedUserData?.userId as string) == this.userId) {
							// CHECK IF THE DELETED AGENCY IS "TOUTES AGENCES" OR THE PREFERED AGENCY
							if (userAgencyRole.key.id == null || this.authenticationManager.store.value.currentUserAgency?.id == userAgencyRole.key.id) {
								// REMOVE THE PREFERED AGENCY
								this.preferenceManager.getUserPreference().subscribe(userPreferences => {
									const preferences: preference = {
										navigation: {
											isCollapsed: this.preferenceManager.store.value.preference?.navigation?.isCollapsed!,
										},
										agency: null,
									};

									this.preferenceManager.updatePreference(JSON.stringify(preferences));
								});

								// RELOAD THE PAGE TO REFRESH CURRENT AGENCY IN THE LEFT MENU
								window.location.reload();
							}
							else {
								// REFRESH THE INTERFACE
								this.userManager.getUserRoles(this.userId!);
							}
						}
						else {
							// REFRESH THE INTERFACE
							this.userManager.getUserRoles(this.userId!);
						}
						return of(null);
					}),
				)
				.subscribe();
		}
		// REMOVE THE LOCAL OBJ
		else {
			// DELETE IT
			this.userAgencyRoles?.delete(userAgencyRole.key);
		}

		// SET THE AGENCY SELECTABLE AGAIN
		this.selectableAgencies.push(userAgencyRole.key);
	}

	public addRole(data: { agency: Agency; role: { label: string; value: number } }) {
		let calls: Observable<boolean>[] = []; // CHECK THE ROLE TO ADD IS "ALL AGENCIES", IF IT IS THE CASE -> DISPLAY A CONFIRMATION MODAL BEFORE TO DELTE ALL OTHER ROLES
		if (data.agency.id == -1) {
			this.confirmationService.confirm({
				message: 'Etes vous sûr de vouloir attribuer le rôle ' + data.role.label + ' pour toute les agences ? Cela supprimera les rôles déjà attribués',
				header: 'Confirmation',
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: 'Oui',
				rejectLabel: 'Annuler',
				accept: () => {
					// DELETE ALL ROLES
					for (let [agency, roles] of this.userAgencyRoles!) {
						roles.forEach(role => {
							// BUILD OBJ TO SEND
							let roleToDelete: userRoleParams = {
								userId: this.userId!,
								agencyId: agency.id!,
								roleId: role.id,
							};
							// CALL SERVER
							calls.push(this.userManager.removeUserRole(roleToDelete));
						});
					}

					combineLatest(calls)
						.pipe(
							debounceTime(0),
							switchMap(() => {
								this.addRoleToAgency(data);
								return of(null);
							}),
						)
						.subscribe();
				},
				reject: () => {
					//REMOVE THE "TOUTES AGENCES" AGENCY ROLE
					this.userAgencyRoles?.delete(data.agency);
				},
				dismissableMask: true,
				closeOnEscape: true,
			});
		} else {
			this.addRoleToAgency(data);
		}
	}

	public removeRole(agency: Agency, role: role) {
		// BUILD DATA TO SEND
		let params: userRoleParams = {
			userId: this.userId!,
			agencyId: agency.id!,
			roleId: role.id,
		};

		// CALL SERVER
		this.userManager.removeUserRole(params).subscribe(res => {
			if (res == true) {
				this.userManager.getUserRoles(this.userId!);
			}
		});
	}

	public getRemainingRoles(userAgencyRole: { key: Agency; value: role[] }) {
		// INIITALIZE THE OBJ TO SEND AND THE INDEX VAR
		let ret = [...this.rolesList];
		let index = null;

		// LOOP ON ROLE ALREADY ADDED
		userAgencyRole.value.forEach(selectedRole => {
			// FIND THE INDEX
			index = ret.findIndex(availableRole => selectedRole.id == availableRole.value);

			// DELETE IT IN THE RET OBJ
			if (index != -1) {
				// DELETE IT
				ret.splice(index, 1);
			}
		});

		return ret;
	}

	private addRoleToAgency(data: { agency: Agency; role: { label: string; value: number } }) {
		// BUILD OBJ TO SEND
		let roleToAdd: userRoleParams = {
			userId: this.userId!,
			agencyId: data.agency.id == -1 ? null : data.agency.id,
			roleId: data.role.value,
		};

		// CALL SERVER TO ADD THE NEW ROLE
		this.userManager.addUserRole(roleToAdd).subscribe(res => {
			if (res == true) {
				this.userManager.getUserRoles(this.userId!);
			}
		});
	}

	private initializeAgencies() {
		// RETRIEVE THE AGENCIES
		this.agencyManager.store.pipe(select(state => state.availableAgencies)).subscribe(agencies => {
			// SET THE AGENCIES
			this.agencies = agencies.concat({
				id: -1,
				name: 'Toutes les agences',
			} as Agency);

			// GET USER ROLES
			this.userManager.getUserRoles(this.userId!);
		});
	}

	private subscribeAvailableRoles() {
		this.userManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select((state: userState) => state.availableRoles),
			)
			.subscribe(roles => {
				if (roles) {
					this.rolesList = roles!.map(r => {
						return {
							label: r.name,
							value: r.id,
						};
					});
				}
			});
	}

	private subscribeUserRoles() {
		this.userManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select((state: userState) => state.userAgenciesRoles),
			)
			.subscribe(data => {
				// SET THE AGENCY ROLES
				this.userAgencyRoles = data || null;

				// REMOVE THE AGENCY WITH ROLES FROM THE SELECTABLE OBJ
				if (this.userAgencyRoles) {
					this.selectableAgencies = [...this.agencies].filter(ag => {
						let found = null;
						this.userAgencyRoles!.forEach((value, key) => {
							if (key.id == ag.id) {
								found = key;
							}
						});
						return found == undefined;
					});
				}
			});
	}

	private subscribeUser() {
		this.userManager.store
			.pipe(
				takeUntil(this.$unsubscribe),
				select((state: userState) => state.currentUser),
			)
			.subscribe(user => {
				this.currentUser = user || null;
			});
	}
}
